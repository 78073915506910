import React, {useEffect, useState, useRef, useContext} from 'react';
import { MainContext } from '../../App';
import Wallet from './income';

const EventWallet = ({itemsPerPage = '8'}) => {
const CTX = useContext(MainContext)
  const data = [
    {signed: 'nil', date: '23/07/2022', name: "portable stores", price: '4342'},
    {signed: 'nil', date: '23/06/2022', name: "portable stores", price: '4342'},
    {signed: 'nil', date: '23/03/2022', name: "portable stores", price: '4342'},
  ];
  const [showModal, setShowModal] = useState(false);
  const [transaction, setTransaction] = useState([]);
  const [loading, setLoading] = useState(false);
  // We start with an empty list of items.
  const [currentItems, setCurrentItems] = useState([]);
  const [pageCount, setPageCount] = useState(0);
  const [pageNum, setPageNum] = useState(1);
  // Here we use item offsets; we could also use page offsets
  // following the API or data you're working with.
  const [itemOffset, setItemOffset] = useState(0);
  const refReactPaginate = useRef();

  const getAllOrder = async () => {
setLoading(true)
    


    try {
  
      const fetched = await fetch(`${CTX.url}/logistic/orders`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Token ${CTX.token}`,
        },
      });
      const json = await fetched.json();
  
      if(json.data === undefined){
        console.log("json.data =>", json);
        console.log("json.data =>", json);
        return
      } else {
        setLoading(false);
        setTransaction(json.data.income_history);

      }
      
    } catch (error) {
      console.log(error)
    }



















    // setTimeout(() => {
    //   setTransaction(data);
    //   setLoading(false);
    // }, 4000);
    // setLoading(true);
  };

  console.log("loading => ", loading)
  console.log("loading => ", loading)


  useEffect(() => {
    getAllOrder();
  }, []);

  useEffect(() => {
    if (transaction.length < 1) return;
    // Fetch items from another resources.carts
    const endOffset = itemOffset + itemsPerPage;
    //  console.log(`Loading carts from ${itemOffset} to ${endOffset}`);
    const allContent = transaction.slice(itemOffset, endOffset);
    const eachPageItem = allContent.splice(0, itemsPerPage);
    //  setCurrentItems(items.slice(itemOffset, endOffset));
    setCurrentItems(eachPageItem);
    setPageCount(Math.ceil(transaction.length / itemsPerPage));
  }, [itemOffset, itemsPerPage, transaction]);

  // Invoke when user click to request another page.
  const handlePageClick = (event) => {
    setPageNum(event.selected + 1);
    const newOffset = (event.selected * itemsPerPage) % transaction.length;
    setItemOffset(newOffset);
  };

  return (
    <Wallet
      loading={loading}
      currentItems={currentItems}
      pageCount={pageCount}
      pageNum={pageNum}
      refReactPaginate={refReactPaginate}
      transaction={transaction}
      showModal={showModal}
      setShowModal={setShowModal}
    />
  );
};

export default EventWallet;
