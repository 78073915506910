import Layout from './component/layout/layout';
import Login from './pages/login/eventLogin';
import React, {useState} from 'react';
import {Navigate, Route, Routes} from 'react-router-dom';
import Verify from './pages/verify/verify';

function App() {
  const url = 'https://api.passward.ng/api/v1';
  const [token, setToken] = useState(sessionStorage.getItem('a_tk') || '');
  const [stats, setStats] = useState(JSON.parse(sessionStorage.getItem('stats')) ||{})
  const [user, setUser] = useState(
    JSON.parse(sessionStorage.getItem('user')) || {}
  );

  const openLogin = () => {
    document.body.setAttribute('data-side-login-mode', 'open');
  };

  const closeLogin = () => {
    document.body.removeAttribute('data-side-login-mode', 'open');
  };

  const removeSideBar = () => {
    document.body.removeAttribute('data-side-navigation-mode');
    document.body.classList.remove('fade-effect');
  };


  const checkLogin = () => {
    if (token.length < 3) {
      openLogin();
    } else {
      return;
    }
  };

  const setTokenHandler = (_token) => {
    setToken(_token);
    closeLogin();
    sessionStorage.setItem('a_tk', _token);
  };

  const setStatsHandler = (_payload) => {
    setStats(_payload)
  }

  const setUserHandler = (_user) => {
    setUser(_user);
    sessionStorage.setItem('user', JSON.stringify(_user));
  };

  const logoutHandler = () => {
    sessionStorage.clear();
    setToken('');
    setUser({});
  };

  
  return (
    <MainContext.Provider
      value={{
        url,
        openLogin,
        closeLogin,
        checkLogin,
        token,
        removeSideBar,
        user,
        setToken: setTokenHandler,
        setUser: setUserHandler,
        logout: logoutHandler,
        setStats: setStatsHandler,
        stats,
      }}
    >
      <Routes>
      {!token && (
                <Route
                  path="/"
                  exact
                  element={<Navigate replace to={'/login'} />}
                />
              )}
              {token && (
                <Route
                  path="/login"
                  exact
                  element={<Navigate replace to={'/dashboard'} />}
                />
              )}
              <Route path="/verify" exact element={<Verify />} />

        {!token && <Route path="/login" element={<Login />} />}
        {!token && (
                <Route
                  path="/*"
                  exact
                  element={<Navigate replace to={'/login'} />}
                />
              )}
      </Routes>

      {token && <Layout />}
    </MainContext.Provider>
  );
}

export default App;

export const MainContext = React.createContext({
  url: null,
  user: null,
  token: null,
  openLogin: () => {},
  closeLogin: () => {},
  checkLogin: () => {},
  setToken: () => {},
  removeSideBar: () => {},
  logout: () => {},
  setUser: () => {},
  setStats: () => {},
  stats: null,
});

//  theres no design for a logistics to submit his or her details and also the register account is for only email
// tried to complete registration with postman, but got errors