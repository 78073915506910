import React, {useContext, useState} from 'react';
import Classes from './nav.module.css';
import {ReactComponent as Logo} from '../../../asset/logo.svg';
import {ReactComponent as Slogo} from '../../../asset/small-logo.svg';
import {ReactComponent as Down} from '../../../asset/down.svg';
import {
  AiOutlineMenu,
  AiOutlineSearch,
  AiOutlineShoppingCart,
  AiOutlineUser,
} from 'react-icons/ai';
import {country} from '../../../data/country';
import {Link} from 'react-router-dom';
import {MainContext} from '../../../App';
import {Modal} from '../../modal/modal';
import Button from '../../button/button';

const Nav = () => {
  const CTX = useContext(MainContext);
  const [showModal, setShowModal] = useState(false);
  const [showDropDown, setShowDropDown] = useState(false);
  const [showSelect, setShowSelect] = useState(false);
  const [selectedCountry, setSelectedCountry] = useState({
    name: {
      common: 'Nigeria',
      official: 'Federal Republic of Nigeria',
    },

    flags: {
      png: 'https://flagcdn.com/w320/ng.png',
      svg: 'https://flagcdn.com/ng.svg',
    },
  });

  const setSelectedHandler = (v, e) => {
    setSelectedCountry(v);
  };

  const handleAllSideBarMenu = () => {
    document.body.setAttribute('data-side-navigation-mode', 'open');
    document.body.classList.add('fade-effect');
  };

  // mapping all the country
  const mapCountry = country
    .sort((a, b) => a.name.common - b.name.common)
    .map((v) => (
      <div
        key={v.flags.png}
        className={Classes.eachCountryCover}
        onClick={setSelectedHandler.bind(this, v)}
      >
        <img
          className={Classes.sectionImage}
          alt="country logo"
          src={v.flags.png}
        />
        <strong className={Classes.strongCountryName}>{v.name.common}</strong>
      </div>
    ));

  return (
    <nav className={Classes.navCoverMain}>
      <div className={Classes.miniNav}>
        <AiOutlineMenu
          onClick={handleAllSideBarMenu}
          size="1.5em"
          className={Classes.AiOutlineMenu}
        />
        <Link style={{textDecoration: "none", color: "inherit"}} to="/home">
          {' '}
          <Logo className={Classes.Logo} />
        </Link>
        <Link style={{textDecoration: "none", color: "inherit"}} to="/home">
          {' '}
          <Slogo className={Classes.Slogo} />
        </Link>

        <ul className={Classes.ulFlex}>
          <Link style={{textDecoration: "none", color: "inherit"}} to="/home">
            <li>Home</li>
          </Link>
          <Link style={{textDecoration: "none", color: "inherit"}} to="/stores">
            <li>Stores</li>
          </Link>
          <Link style={{textDecoration: "none", color: "inherit"}} to="/pricing">
            <li>Pricing</li>
          </Link>
          <Link style={{textDecoration: "none", color: "inherit"}} to="/blog">
            <li>Blog</li>
          </Link>
        </ul>
        <div className={Classes.rightSideNavDesign}>
          <form className={Classes.searchFormSection}>
            <input className={Classes.searchInputSection} />
            <div
              onClick={() => setShowSelect(!showSelect)}
              className={Classes.customSelectSection}
            >
              <img
                className={Classes.sectionImage}
                alt="country logo"
                src={selectedCountry.flags.png}
              />
              <strong className={Classes.strongCountryName}>
                {selectedCountry.name.common}
              </strong>
              <Down
                style={{
                  width: '15px',
                  marginTop: '3px',
                  transform: showSelect ? 'rotate(180deg)' : 'rotate(0deg)',
                }}
              />
            </div>
            <button className={Classes.mainSearchSectionBTN}>
              <AiOutlineSearch size="1.8em" fill="#fff" />
            </button>
            {showSelect && (
              <div className={Classes.allCountryMap}>{mapCountry}</div>
            )}
          </form>
          {CTX.token && (
            <div
              onClick={() => setShowDropDown(!showDropDown)}
              className={Classes.profileRightSideNav}
            >
              <AiOutlineUser size="1.3em" style={{fill: '#000033'}} />
              <Down style={{width: '15px', marginTop: '3px'}} />
            </div>
          )}

          {showDropDown  && (
            <div
              style={{top: showDropDown ? '0%' : '100%'}}
              className={Classes.mainNavDrop}
            >
              <div
                className={Classes.textOverflow}
                style={{display: 'flex', textTransform: 'capitalize'}}
              >
                <strong style={{marginRight: '8px'}}>Name</strong>{' '}
                <small style={{fontSize: '12px', marginLeft: '4px'}}>
                  {CTX.user?.first_name}
                </small>{' '}
                <small style={{fontSize: '12px', marginLeft: '4px'}}>
                  {CTX.user?.last_name}
                </small>
              </div>
              <div
                className={Classes.textOverflow}
                style={{display: 'flex', textTransform: 'capitalize'}}
              >
                <strong style={{marginRight: '8px'}}>State</strong>{' '}
                <small style={{fontSize: '12px', marginLeft: '4px'}}>
                  {CTX.user?.state}
                </small>
              </div>
              <Link to="/dashboard"  onClick={() => setShowDropDown(!showDropDown)}>
              <div className={Classes.eachNavLinkSide}>
                <small style={{fontSize: '12px', marginLeft: '4px'}}>
                  Dashboard
                </small>
              </div>
              </Link>
              <div className={Classes.eachNavLinkSide}>
                <small style={{fontSize: '12px', marginLeft: '4px'}}>
                  Profile
                </small>
              </div>

              <div className={Classes.dropdownDivider}></div>
              <div
                className={Classes.eachNavLinkSide}
                style={{display: 'flex', cursor: "pointer"}}
                onClick={() => {setShowModal(!showModal); setShowDropDown(!showDropDown)}}
              >
                <span
                  style={{
                    fontSize: '12px',
                    marginTop: '3px',
                    marginLeft: '2px',
                  }}
                >
                  Logout
                </span>
              </div>
            </div>
          )}
          <Link to="/my-cart" style={{position: "relative"}}>
            {/* {CTX.cart.length > 0 && <div className={Classes.topRightCounter}>
            {CTX.cart.length}
            </div>}
            <AiOutlineShoppingCart
              size="1.5em"
              style={{fill: CTX.cart.length > 0 ? "#ff0066" : '#969696', cursor: 'pointer'}}
            /> */}
          </Link>
        </div>
      </div>

      <div className={Classes.secondFormCover}>
        <form className={Classes.secondSearchFormSection}>
          <input className={Classes.searchInputSection} />
          <div
            onClick={() => setShowSelect(!showSelect)}
            className={Classes.customSelectSection}
          >
            <img
              className={Classes.sectionImage}
              alt="country logo"
              src={selectedCountry.flags.png}
            />
            <strong className={Classes.strongCountryName}>
              {selectedCountry.name.common}
            </strong>
            <Down
              style={{
                width: '15px',
                marginTop: '3px',
                transform: showSelect ? 'rotate(180deg)' : 'rotate(0deg)',
              }}
            />
          </div>
          <button className={Classes.mainSearchSectionBTN}>
            <AiOutlineSearch size="1.8em" fill="#fff" />
          </button>
          {showSelect && (
            <div className={Classes.allCountryMap}>{mapCountry}</div>
          )}
        </form>
      </div>
      <Modal show={showModal} close={() => {setShowModal(false); setShowDropDown(false)}}>
        <h3 className={Classes.confirmRemoval}>Log out of Passward</h3>
        <p className={Classes.removed}>
          This will log you out of your account, all your data will be saved.
          Continue to Log Out?
        </p>

        <div style={{display: 'flex'}}>
          <Button
            text="Yes, Log Out"
            style={{width: '160px'}}
            onClick={() => {CTX.logout(); setShowModal(false)}}
          />
          <div style={{width: '20px'}}></div>
          <Button
            text="Don't Log Out"
            onClick={() => setShowModal(false)}
            style={{
              backgroundColor: 'inherit',
              border: '2px solid #ff0066',
              color: '#ff0066',
              width: '160px',
            }}
          />
        </div>
      </Modal>
    </nav>
  );
};

export default Nav;
