import React, {useEffect, useState, useContext} from 'react';
import Classes from './withdrawalSettings.module.css';
import Input from '../../component/input/input';
import Card from '../../component/card/card';
import Button from '../../component/button/button';
import {Banks} from '../../data/bankJson';
import axios from 'axios';
import {MainContext} from '../../App';
import {GetUserDetails} from '../../helper/getUserHelper';
import {useLocation} from 'react-router-dom';
import {States} from '../../data/stateJson';
import {Link} from 'react-router-dom';

const RightSide = () => {
  const [toggle, setToggler] = useState(false);
  const location = useLocation();
  const {getUser} = GetUserDetails();
  const CTX = useContext(MainContext);
  const [withdrawalInput, setWithdrawalInput] = useState({
    ...CTX.user
  });
  const [state, setState] = useState('');
  const [inputs, setInputs] = useState({...CTX.user});
  const [selectedBank, setSelectedBank] = useState('Abbey Mortgage Bank');
  // const [loadingFetch, setLoading] = useState(false);
  const [msg, setMsg] = useState('');
  const [loading, setLoading] = useState(false);
  const [image, setImage] = useState({})

  const onSubmitBankHandler = async (e) => {
    e.preventDefault();

    setLoading(true);
    try {
      const request = await axios({
        url: `${CTX.url}/agent/setting/withdraw`,
        method: 'put',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Token ${CTX.token}`,
        },
        data: {
          bank: selectedBank,
          account_no: withdrawalInput.account_num,
          account_name: withdrawalInput.account_name,
        },
      });
      console.log('request', request);
      setLoading(false);
      setTimeout(() => {
        setMsg('');
      }, 4000);
      setMsg('Details saved successfully');
      const sad = getUser();
    } catch (error) {
      setLoading(false);
      setTimeout(() => {
        setMsg('');
      }, 4000);
      setMsg('Invalid details');
      console.log(error);
    }
  };

  const onSubmitDetailsHandler = async (e) => {
    e.preventDefault();
    setLoading(true);
    try {
      var formData = await new FormData();
      formData.append('photo', image);
      formData.append('first_name', inputs.first_name);
      formData.append('last_name', inputs.last_name);
      formData.append('phone', inputs.phone);
      formData.append('price', inputs.fee);
      formData.append('extra', inputs.extra);
      formData.append('company_name', inputs.name);
      formData.append('address', inputs.address);
      formData.append('state', state);
      formData.append('city', inputs.city);
      formData.append('no_of_vehicles', inputs.no_of_vehicles);
      formData.append('vehicle', inputs.vehicle);
      formData.append('cac', inputs.cac);

      const request = await axios({
        url: `${CTX.url}/agent/setting/account`,
        method: 'put',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Token ${CTX.token}`,
        },
        data: formData
      });
      // console.log('request', request);
      setLoading(false);
      setMsg('Details saved successfully');
      const sad = getUser();
    } catch (error) {
      setLoading(false);
      setMsg('Invalid details');
      console.log(error);
    }
  };

  useEffect(() => {
    if (location.search.split('=')[1] === 'withdrawal') {
      setToggler(true);
    } else {
      setToggler(false);
    }
    // window.location.search.split('=')[0] === '?token'
  }, [location]);

  // uploading the profile image
  const onBlurImageHandler = async (e) => {
    console.log('e.target => ', e.target);
    try {
      var formData = await new FormData();
      formData.append('photo', e.target.files[0]);
      const request = await axios({
        url: `${CTX.url}/agent/setting/account/avatar/update`,
        data: formData,
        method: 'put',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Token ${CTX.token}`,
        },
      });
      const sad = getUser();
    } catch (error) {
      console.log(error);
    }
  };

  console.log("inputs => ", inputs)

  return (
    <div>
      <div className={Classes.pageHeader}>Account/Withdrawal Settings</div>
      <Card style={{height: 'max-content'}}>
        <div className={Classes.paddingCard}>
          <div className={Classes.flexTwo}>
            <Link
              to="/settings?type=account"
              style={{textDecoration: 'none', marginRight: '50px'}}
            >
              <div
                style={{
                  backgroundColor: !toggle ? '#ff0166' : '#f7f7f7',
                  color: !toggle ? '#fff' : '#6c757d',
                }}
                onClick={() => {
                  setToggler(false);
                  setMsg('');
                }}
                className={Classes.twoRoutes}
              >
                Account
              </div>
            </Link>
            <Link
              to="/settings?type=withdrawal"
              style={{textDecoration: 'none'}}
            >
              <div
                style={{
                  backgroundColor: toggle ? '#ff0166' : '#f7f7f7',
                  color: toggle ? '#fff' : '#6c757d',
                }}
                onClick={() => {
                  setToggler(true);
                  setMsg('');
                }}
                className={Classes.twoRoutes}
              >
                Withdrawal
              </div>
            </Link>
          </div>

          {!toggle && (
            <form onSubmit={onSubmitDetailsHandler}>
              {msg.length > 0 && (
                <div
                  className={Classes.msgAlert}
                  style={{textAlign: 'center', marginBottom: '40px'}}
                >
                  <p style={{margin: '0px 0px'}} className={Classes.msgItself}>
                    {msg}
                  </p>
                </div>
              )}
              <div className={Classes.personalInfoCover}>
                <span style={{lineHeight: '1'}}>PERSONAL INFORMATION</span>
              </div>
              <div className={Classes.gridTwo}>
              <Input
                  value={inputs?.first_name}
                  onChange={(e) =>
                    setInputs({...inputs, first_name: e.target.value})
                  }
                  type="input"
                  placeholder="First name"
                  label="First Name"
                />

<Input
                  value={inputs?.last_name}
                  onChange={(e) =>
                    setInputs({...inputs, last_name: e.target.value})
                  }
                  type="input"
                  placeholder="Last Name"
                  label="Last Name"
                />
              </div>
                <Input
                  value={inputs?.name}
                  onChange={(e) =>
                    setInputs({...inputs, name: e.target.value})
                  }
                  type="input"
                  placeholder="Enter company name"
                  label="Logistics Company Name"
                />

              <div className={Classes.gridTwo}>
                <Input
                  type="input"
                  inputType="email"
                  label="Email Address"
                  value={CTX.user.email}
                  readOnly={true}
                  msg="Registered email address can not be modified."
                />
                <Input
                  value={inputs?.phone}
                  onChange={(e) =>
                    setInputs({...inputs, phone: e.target.value})
                  }
                  type="input"
                  label="Contact Number/Help Line"
                />
              </div>
              <Input
                  type="input"
                  onChange={e => setImage(e.target.files[0])}
                  // onBlur={onBlurImageHandler}
                  inputType="file"
                  accept="image/png, image/gif, image/jpeg"
                  label="Profile Image"
                />
              <div className={Classes.gridTwo}>
              <Input
                  value={inputs?.fee}
                  onChange={(e) =>
                    setInputs({...inputs, fee: e.target.value})
                  }
                  type="input"
                  label="Delivery Fee"
                />

<Input
                  value={inputs?.extra}
                  onChange={(e) =>
                    setInputs({...inputs, extra: e.target.value})
                  }
                  type="input"
                  label="Extra Fee"
                />


                <Input
                  selected={inputs?.state}
                  type="select"
                  onChange={(e) => setState(e.target.value)}
                  label="State of Residence"
                  options={States.map((v) => v.name)}
                />
               
               <Input
                  value={inputs?.address}
                  onChange={(e) =>
                    setInputs({...inputs, address: e.target.value})
                  }
                  type="input"
                  label="Address"
                />


<Input
                  value={inputs?.city}
                  onChange={(e) =>
                    setInputs({...inputs, city: e.target.value})
                  }
                  type="input"
                  label="City"
                />



<Input
                  value={inputs?.no_of_vehicles}
                  onChange={(e) =>
                    setInputs({...inputs, no_of_vehicles: e.target.value})
                  }
                  type="input"  
                  label="No of Vehicles"
                />


<Input
                  value={inputs?.vehicle}
                  onChange={(e) =>
                    setInputs({...inputs, vehicle: e.target.value})
                  }
                  type="input"
                  label="No of Vehicles"
                />


<Input
                  value={inputs?.cac}
                  onChange={(e) =>
                    setInputs({...inputs, cac: e.target.value})
                  }
                  type="input"
                  label="CAC "
                />
               
                <div></div>
              </div>


              <Button
                style={{marginTop: '30px'}}
                loading={loading}
                style={{height: '38px'}}
                text="Save Changes"
                bgColor="#1abc9c"
              />
            </form>
          )}

          {toggle && (
            <form onSubmit={onSubmitBankHandler}>
              <div className={Classes.personalInfoCover}>
                <span style={{lineHeight: '1'}}>WITHDRAWAL INFORMATION</span>
              </div>
              <Input
                type="select"
                  selected={inputs?.bank}
                  options={Banks.map((v) => {
                  return v.name;
                })}
                label="Bank"
                onChange={(e) => setSelectedBank(e.target.value)}
              />
              <div className={Classes.gridTwo}>
                <Input
                  required
                  value={withdrawalInput?.account_name}
                  onChange={(e) =>
                    setWithdrawalInput({
                      ...withdrawalInput,
                      account_name: e.target.value,
                    })
                  }
                  type="input"
                  inputType="text"
                  label="Account Name"
                />
                <Input
                  required
                  value={withdrawalInput?.account_num}
                  onChange={(e) =>
                    setWithdrawalInput({
                      ...withdrawalInput,
                      account_num: e.target.value,
                    })
                  }
                  type="input"
                  label="Account No"
                />
              </div>

              <Button
                style={{height: '38px'}}
                text="Save Changes"
                loading={loading}
              />
            </form>
          )}
        </div>
      </Card>
    </div>
  );
};

export default RightSide;
